.generic-table-pagination {
  &-bottom {
    margin-top: 10px;
  }

  &-toolbar {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }

  &-caption {
    display: none;
  }

  &-spacer {
    display: none;
  }

  &-text {
    margin: 0 2px;
    font-weight: bold;
  }

  &-button {
    color: #007681;
    padding: 4px;

    .MuiSvgIcon-root {
      margin: 0;
      font-size: 21px;
    }
  }

  &-last-page {
    margin-left: -2px;

    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }

  &-last-page {
    margin-right: -2px;
  }

  &-previous-page {
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }

  &-next-page,
  &-previous-page {
    .MuiSvgIcon-root {
      font-size: 18.1px;
    }
  }

  &-disabled.Mui-disabled {
    color: #E7E8E8;
  }
}
