.generic_select {
  &__form {
    .MuiOutlinedInput-root {
      font-size: 0.75rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #888B8D;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #888B8D;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      padding-right: 42px;
      display: flex;
      align-items: center;
    }

    &.generic-select-placeholder-selected {
      .generic_select__select {
        color: #E7E8E8;
        opacity: 0.42;
        font-size: 1rem;
      }
    }
  }

  &__label {
    font-style: italic;
    color: #4F4F4F;
  }

  &__asterisk {
    color: #EB5757;
    font-size: 0.75rem;
  }

  &__select {
    padding: 0 24px 0 0;
  }

  &__select-outlined {
    height: 32px;
    padding: 0 14px;

    .MuiListItemText-root {
      margin: 0;
    }

    .MuiTypography-body1 {
      font-size: 12px;
    }

    &:focus {
      background-color: transparent;
    }
  }

  &__icon {
    position: absolute;
    right: 8px;
    pointer-events: none;
    font-size: 32px;
    color: #007681;
  }
}

.generic_menu-select-paper {
  max-height: 300px;
}
