@import "~styles/variables.scss";

/* Base styles for the dropdown container */
.dropdown-container {
  background-color: white;
  min-width: 280px;
  z-index: 1000;
  position: absolute;
  display: none;
  padding-left: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 0;
}

/* Triangle arrow styles */
.dropdown-container:after {
  content: " ";
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -10px;
  right: 14px;
}

/* List item styles */
.dropdown-list {
  list-style-type: none;
  color: blue;
  font-size: 16px;
  display: block;
  margin: 0;
}

/* Hover effect on list items */
.dropdown-list:hover {
  background-color: grey;
  border-radius: 3px;
}

/* Display dropdown on hover */
.dropdown-wrapper:hover .dropdown-container {
  display: block;
}

/* Additional styles for responsiveness */
@media screen and (max-width: 768px) {
  .dropdown-container {
    min-width: 40%; /* Make the dropdown full-width on small screens */
    box-shadow: none; /* Remove box shadow on small screens if desired */
    right: 0; /* Adjust position for full-width dropdown */
  }

  .dropdown-container:after {
    display: none; /* Hide the triangle arrow on small screens */
  }
}
