@import "~styles/variables.scss";

.edit-installation-address-modal {
  .generic-modal-window {
    @media screen and (max-width: $mobile-max-width) {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: $tablet-min-width) {
      width: 502px;
    }
  }
}
