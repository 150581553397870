@import "~styles/variables.scss";

.license-agreement-section {
  @media screen and (max-width: $mobile-max-width) {
    margin-top: 18px;
  }
  
  @media screen and (min-width: $tablet-min-width) {
    margin-top: 26px;
  }
  
  display: flex;
  flex-direction: column;

  .license-header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .license-header-text {
    @media screen and (max-width: $mobile-max-width) {
      font-size: 1.2857rem;
    }
    @media screen and (min-width: $tablet-min-width) {
      font-size: 1.5rem;
    }
    margin-left: 10px;
  }

  .license-agreement-link {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: bold;

    @media screen and (max-width: $mobile-max-width) {
      font-size: 0.85714rem;
    }

    &--icon.MuiSvgIcon-root {
      margin-left: 5px;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 0.85714rem;
      }

      @media screen and (min-width: $tablet-min-width) {
        font-size: 1rem;
      }
    }
  }
}

