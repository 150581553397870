@import "~styles/variables.scss";
@import "~styles/flexbox.scss";

/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

a {
  color: black;
  &:hover {
    text-decoration: none;
    color: #004a55;
  }
  &:not([href]) {
    cursor: pointer;
    
    &:hover {
      color: #004a55;
      text-decoration: none;
    }
  }

  &.MuiButton-root {
    color: white;
  }
}

code {
  color: #e01a76;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  color: black;
}

*:focus,
button:focus {
  outline: none;
}

.app-content {
  min-height: 100%;
  position:relative;

  @media screen and (max-width: $mobile-max-width) {
    padding-top: 56px
  }

  @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    padding-top: 64px
  }

  @media screen and (min-width: $desktop-min-width) {
    padding-top: 88px
  }
}

.main-container {
  padding-bottom: 105px;
}

.app-container {
  height: 100%;
}
.app-content-overlay {
  height: 100vh;
  overflow: auto;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.9;
}

.simulation-buttons {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
}

.simulate-cancel-btn {
  margin-right: 15px;
}

.cookie-buttons {
  height: 30px;
  outline: none;
  border: none;
  transform: none;
  font-size: 1rem;
  width: 150px;
  background-color: #007681;
  color: white;
}
.alert-warning {
  display: flex;
}
.text-capitalize {
  margin-bottom: 10px;
  display: inline-flex;
  text-transform: inherit !important;
  font-size: 1rem;
  margin-right: 25px;
   text-transform: inherit !important;
}
.text-capitalize > a {
  margin-left: 7px;
  margin-right: 7px;
}
.text-bold {
  font-weight: bold;
}
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
#root {
  height: 100%;
  width: 100%;
}
.dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border: 1px solid #dededf;
  border-radius: 4px;
  opacity: 1;
}

.dropdown-item.active {
  color: #fff;
  text-decoration: none;
  /* background-color: #007bff; */
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  letter-spacing: 0px;
  color: #131313;
  opacity: 1;
  font-size: 0.875rem;
  font-weight: bold;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin: 0rem;
}

.dropdown-item {
  letter-spacing: 0px;
  color: #131313;
  opacity: 1;
  font-size: 0.875rem;
  padding: 8px;
}

:root {
  font-size: 16px;

  @media screen and (max-width: $desktop-min-width) {
    font-size: 14px;
  }
}

p {
  margin: 0;
}

.input-error,
.autocomplete-error .mui-autocomplete-inputRoot fieldset {
  border: 1px solid #d33e3e;
}

.error-message {
  color: #d33e3e;
  margin-top: 5px;
}

.swal2-container {
  z-index: 1301;
}
