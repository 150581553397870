@import "~styles/variables.scss";

.account-info--billing-address {
  display: flex;
  flex-direction: column;

  .account-info-primary-tile--body {
    flex-direction: row;
  }

  .account-info-icon-left-align {
    margin: 0 12px 0 -12px;
  }

  .account-info-primary-contact-row {
    display: flex;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .account-info-your-organization-tile {
    max-width: 733px;
    margin-top: 18px;

    .account-info-primary-tile--body {
      @media screen and (max-width: $mobile-max-width) {
        flex-direction: column;
      }
    }
  }

  .account-info-billing-address-tile {
    @media screen and (min-width: $tablet-min-width) {
      width: 304px;
      max-width: 304px;
    }
  }

  .account-info-primary-contact-tile {
    @media screen and (min-width: $tablet-min-width) {
      margin-left: 12px;
      width: 394px;
      max-width: 394px;
    }

    @media screen and (max-width: $mobile-max-width) {
      margin-top: 12px;
    }
    .account-info-secondary-tile--body {
      display: flex;
      flex-direction: column;
    }
  }

  .primary-contact-mail {
    text-decoration: underline;
    color: #007681;
  }
}

.account-info--title-wrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: $mobile-max-width) {
    font-size: 1.2857rem;
  }

  @media screen and (min-width: $tablet-min-width) {
    font-size: 1.5rem;
  }

  .billing-title {
    margin-left: 12px;
  }
}
