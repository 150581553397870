.generic-table {
  &-table {
    border-collapse: separate;
    border-spacing: 0 8px;
    background-color: #F2F2F2;
  }

  &-thead {
    height: 42px;
  }

  &-head-row {
    background-color: #E7E8E8;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 42px;

    .MuiSvgIcon-root {
      margin-right: 8px;
    }
  }

  &-body-row {
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    height: 42px;
    // cursor: pointer;

    .generic-table-root-cell {
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &-filled {
      background-color: #53A4B0;

      .generic-table-root-cell {
        color: #F2F2F2;
        font-weight: bold;
      }
    }
  }

  &-root-cell {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    color: #4f4f4f;
    font-size: 1rem;

    &-inner {
      display: flex;
      align-items: center;
    }
  }

  &-head-cell {
    font-weight: bold;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}