@import "~styles/variables.scss";

.custom-CMS-info {
  &--wrapper {
    color: #4F4F4F;

    @media screen and (max-width: $mobile-max-width) {
      padding: 16px 18px 97px;
    }

    @media screen and (min-width: $tablet-min-width) {
      padding: 30px 24px 90px;
    }

    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }

  &--title {
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 22px;
    padding-bottom: 8px;

    @media screen and (max-width: $mobile-max-width) {
      margin-bottom: 10px;
    }

    @media screen and (min-width: $tablet-min-width) {
      margin-bottom: 22px;
    }

    &-heading {
      font-weight: bold;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 18px;
        margin-bottom: 4px;
      }

      @media screen and (min-width: $tablet-min-width) {
        font-size: 1.5rem;
        margin-bottom: 6px;
      }
    }

  }

  &--installations {
    margin-top: 30px;

    .installations-title-wrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 1.2857rem;
      }

      @media screen and (min-width: $tablet-min-width) {
        font-size: 1.5rem;
      }

      .installations-title {
        margin-left: 10px;
      }
    }

    .installations-content {
      display: flex;

      @media screen and (max-width: $mobile-max-width) {
        flex-direction: column;
        margin-top: 30px;
      }

      @media screen and (min-width: $tablet-min-width) {
        flex-wrap: wrap;
        margin: 8px -14px 0 -7px;
      }
    }
  }
}