.generic-paper {
  border-radius: 4px;
  
  &-white {
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  &-gray {
    border: 1px solid #BDBDBD;
    background-color: #F2F2F2;
  }

  &-disabled:after {
    content: '';
    position: absolute;
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.9;
  }
}
