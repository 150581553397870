@import "~styles/variables.scss";

.edit-system-alias-modal {
  .generic-modal-window {
    @media screen and (max-width: $mobile-max-width) {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: $tablet-min-width) {
      width: 525px;
    }
  }

  .generic-modal-footer {
    justify-content: center;
  }

  &-heading {
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 8px;
  }
}
