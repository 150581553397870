.pump-hours-chosen-image {
  border-radius: 4px;
  align-self: center;
  margin: 0 auto;

  &-wrapper {
    border: 1px solid #888B8D;
    border-radius: 4px;
    width: 106.67px;
    height: 106.67px;
    background-color: #F2F2F2;
    padding: 12px 8px 7.67px;
  }

  &-delete-button {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    // background-color: #007681;
    position: absolute;
    top: -13px;
    right: -13px;
    color: white;
    cursor: pointer;

    &-wrapper {
      position: relative;
      margin-left: 47px;

      &:first-child {
        margin-left: 0;
      }
    }

    .pump-hours-delete-icon {
      font-size: 16px;
    }
  }

  &-name {
    font-size: 12px;
  }

  &-size {
    font-size: 10px;
  }
}