.all-videos {
  padding: 30px 16px;
  color: #4f4f4f;

  .docs-and-videos-header-title {
    margin-top: 0;
  }

  &-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    margin-top: 23px;

    &-tile {
      padding: 12px 12px 18px;
      line-height: 23.5px;
      
      &-title {
        font-weight: bold;
        margin-bottom: 8px;
      }
    }
  } 
}
