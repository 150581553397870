@import "~styles/mixins.scss";

.pump-hours-modal {
  @include modal;

  .details-field-helper-text-root {
    text-align: right;
    margin-right: 0;
    font-size: 1rem;
    color: #888B8D;
    position: absolute;
    right: 0;
    bottom: -8px;
  }

  .pump-hours-selected-files-text {
    font-size: 1rem;
    margin-left: 8px;
  }

  .pump-hours-file-upload-text {
    margin-bottom: 4px;
    line-height: 20px;
    font-size: 1rem;
  }

  .pump-hours-file-restrictions-text {
    font-size: 0.75rem;
    line-height: 12px;
    margin-top: 4px;
  }

  .add-file-button-label {
    margin: 0;

    .button {
      height: 40px;
    }
  }

  .button-disabled-state {
    color: #888B8D;
  }

  .pump-hours-chosen-images {
    margin-top: 24px;
  }

  .pump-hours-modal-details-input {
    .input-base-root {
      height: 80px;
      margin-bottom: 16px;
    }
  }
}
