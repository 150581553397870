@import "~styles/variables.scss";

.generic-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4F4F4F;

  &-window {
    border-radius: 4px;
    outline: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 952px;
    overflow-y: auto;
    max-height: 91vh;

    @media screen and (max-width: $mobile-max-width) {
      justify-content: space-between;
    }
  }

  &-header {
    background-color: #F2F2F2;
    color: #4F4F4F;

    @media screen and (max-width: $mobile-max-width) {
      padding: 24.75px 25px 27.25px;
      font-size: 1.28571rem;
    }

    @media screen and (min-width: $tablet-min-width) {
      padding: 24.75px 41px 27.25px 64px;
      font-size: 1.5rem;
    }
  }

  &-body {
    border-top: 1px solid #BDBDBD;
    overflow-y: auto;

    @media screen and (max-width: $mobile-max-width) {
      padding: 32px 31px;
    }
    
    @media screen and (min-width: $tablet-min-width) {
      padding: 64px;
    }
  }

  &-footer {
    display: flex;
    background-color: #F2F2F2;
    border-top: 1px solid #BDBDBD;

    @media screen and (max-width: $mobile-max-width) {
      padding: 32px 31px;
      justify-content: center;
    }

    @media screen and (min-width: $tablet-min-width) {
      padding: 32px 64px;
    }
  }

  .generic-input-root {
    width: 100%;
  }
}
