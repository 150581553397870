.password-reset {
  &--wrapper {
    background-color: #007681;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &--tile {
    width: 600px;
    min-height: 50vh;
    background-color: white;
    padding: 42px 100px;
    overflow-y: auto;

    .header-logo {
      width: 60%;
      height: 55px;
    }

    .password-reset-message {
      margin-top: 80px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }

    .tile-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 35px;
    }

    .login-contact {
      color: #5B6770;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .contact-email {
      cursor: pointer;

      a {
        text-decoration: underline;
      }
    }

    @media only screen and (max-width: 1023px) {
      width: 100%;
      height: 100%;
      padding: 50px 10px;
      font-size: 25px;
    }
  }

  &--tile-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  &--login-btn {
    height: 50px;
    margin-top: 80px;
    width: 100%;
    font-size: 1em;
  }
}