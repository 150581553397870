@import "~styles/mixins.scss";

.docs-and-videos {
  padding: 30px 16px;
  color: #4f4f4f;

  &-header {
    @include flow-page-main-header;

    &-icon {
      margin-right: 8px;
      font-size: 3.07375rem;
    }
  }

  &-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, 176px);
    margin-top: 18px;
    gap: 16px;
  }

  &-tile {
    position: relative;
  }

  .landing-page-tile-inner {
    padding-bottom: 4px;
  }

  .landing-page-tile .tile-image {
    height: 51px;
  }

  &-thumbs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 12px; 
    margin-top: 12px;
  }

  &-main-paper {
    margin-top: 30px;
    padding: 12px 12px 18px;
    line-height: 23.5px;
    
    .bordered-heading {
      font-size: 1.125rem;
    }
  }

  &-item {
    padding: 12px;

    &-title {
      margin-bottom: 8px;
    }

    &-image {
      width: 100%;
      cursor: pointer;
      
      &-container {
        position: relative;
      }

      &-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-size: 69px;
      }
    }
  }

  .videos-see-more-button {
    &:hover {
      background-color: transparent;
    }
  }

  .MuiButton-text {
    padding-top: 0;
    padding-bottom: 0;
    height: 22px;
  }
}
