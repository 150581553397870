@import "~styles/variables.scss";

.landing-page-swiper {
  height: 100%;

  .slide-text-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;

    @media screen and (min-width: $tablet-min-width) {
      font-size: 2rem;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    @media screen and (max-width: $mobile-max-width) {
      width: 10px
    }

    @media screen and (min-width: $tablet-min-width) {
      width: 16px;
    }
  }

  .swiper-button-next {
    background-image: url("./../../assets/chevron_next.svg");

    @media screen and (max-width: $mobile-max-width) {
      right: 24px;
    }

    @media screen and (min-width: $tablet-min-width) {
      right: 34px;
    }
  }

  .swiper-button-prev {
    background-image: url("./../../assets/chevron_prev.svg");

    @media screen and (max-width: $mobile-max-width) {
      left: 24px;
    }

    @media screen and (min-width: $tablet-min-width) {
      left: 34px;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }

}