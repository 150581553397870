.table-img,
.table-img-enable {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.table-sort {
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-left: 6px;
}
.table-img-enable {
  margin-left: 5px;
}
.table-img-status {
  height: 14px;
  width: 20px;
  cursor: pointer;
  margin-right: 5px;
}
table {
  overflow: auto;
  width: 100%;
  height: calc(100% - 50px);
  display: inline-block;
}
table,
thead,
tr {
  width: 100%;
}
thead {
  height: 60px;
}
tbody {
  height: calc(100% - 60px);
  overflow: auto;
}
tbody > tr {
  width: 100%;
  height: 64px;
}
.table-search > input {
  display: inline-block;
  height: 30px;
  margin-left: 10px;
  width: 70% !important;
  outline: 1px solid #007681;
}
.table-search > input:focus {
  outline: 2px solid #007681;
  border-radius: 2px;
}
.enabled {
  margin-right: 10px;
  cursor: pointer;
}
.no-data-available {
  font-size: 4rem;
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
}
.pagination {
  margin-top: 20px;
  justify-content: center;
  height: 30px;
}
.table-pagination-buttons,
.table-pagination-page-count,
.table-pagination-goto,
.table-pagination-select {
  margin-left: 15px;
  display: inline-flex;
}
.table-pagination-buttons {
  border: none !important;
  background-color: white;
}
.table-pagination-buttons:focus {
  outline: none;
}
.table-pagination-button-img {
  height: 18px;
  width: 18px;
}
.table-pagination-goto > input {
  display: inline-block;
  height: 30px;
  width: 35%;
  margin-left: 15px;
}
.table-pagination-goto {
  width: 180px;
}
.table-pagination-page-count > strong {
  margin-left: 5px;
}
