.service-history-table-container {
  @media (max-width: 600px) {
    .mobile-tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .mobile-tile {
      width: 100%;
      margin-bottom: 16px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 8px;

      div {
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 601px) {
    // Add styles for larger screens if needed
  }
}
