@mixin flow-page-main-header {
  line-height: 20px;
  border-bottom: 1px solid #BDBDBD;
  padding-bottom: 8px;

  &-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 6px;
  }

  &-description {
    margin-top: 9px;
  }

  &-icon {
    font-size: 3.6875rem;
    margin-right: 4px;
    color: #007681;
  }

  &-button {
    height: 42px;
    width: 176px;
  }
}

@mixin modal {
  .generic-modal-window {
    width: 544px;
    line-height: 20px;
  }

  .generic-modal-footer {
    justify-content: center;
  }

  .generic_select__form {
    width: 100%;
  }

  .generic_select__label {
    font-style: normal;
    margin-bottom: 4px;
  }

  .generic_select__select-outlined {
    height: 40px;

    .MuiTypography-body1 {
      font-size: 1rem;
    }
  }

  .input-label-asterisk {
    font-size: 0.75rem;
  }

  &-form {
    margin-top: 24px;
  }

  &-description {
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: 1px solid #BDBDBD;
    line-height: 19px;
  }

  &-input-line {
    margin-top: 16px;

    textarea::placeholder,
    input::placeholder {
      color: #E7E8E8;
    }
  }
}