.manage-customer {
  height: 100vh;
  padding: 25px 50px;
}
.manage-table {
  margin-top: 20px;
  height: calc(100% - 70px);
}

.manage-buttons {
  height: 50px;
  outline: none;
  border: none;
  transform: none;
  font-size: 1rem;
  width: 300px;
  background-color: #007681;
  color: white;
}

.manage-customer-mobile {
  height: 100vh;
  padding: 0;

  .onboard-button-container {
    padding: 10px 20px 0;
  }

  .icon-plus {
    font-size: 1.5rem;
  }
}
.manage-table-mobile {
  margin-top: 20px;
  height: calc(100% - 70px);
  overflow: auto;
}

.manage-buttons-mobile {
  height: 30px;
  outline: none;
  border: none;
  transform: none;
  font-size: 1.5rem;
  margin: 10px;
  width: 200px;
  background-color: #007681;
  color: white;
}

@media only screen and (max-device-width: 850px) and (max-device-height: 850px) {
  .manage-buttons {
    //font-size: 2rem;
  }
}
