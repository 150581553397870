@import "~styles/variables.scss";

.footer-div {
  width: 100%;
  background-color: #E7E8E8;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5B6770;
  font-size: 0.625rem;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: $mobile-max-width) {
    padding: 15px 10px 12px;
    font-size: 0.571428rem;
  }

  @media screen and (min-width: $tablet-min-width) {
    padding: 10px 15px;
  }

  .footer-logo {
    display: flex;
    margin-bottom: 4px;
    @media screen and (max-width: $mobile-max-width) {
      .shape-logo {
        width: 25px;
      }
    }
  }

  .links-wrapper {
    display: inline;
  }

  .footer-link-terms,
  .footer-link-privacy {
    color: #5B6770;
    display: inline;
  }

  .footer-link-terms {
    padding-left: 4px;
  }

  .footer-link-privacy {
    padding-right: 4px;
  }
}

