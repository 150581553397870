@import "~styles/variables.scss";

.tile-holder {
  position: relative;

  .tile-coming-soon {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #3a3a3a;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
}

.landing-page-tile {
  background-color: #F2F2F2;
  color: #4f4f4f;
  padding: 8px;
  border-radius: 4px;

  &-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 167px;
  }

  .tile {
    &-image {
      height: 68px;

      .logo-wrapper {
        svg {
          // color: #007681;
          font-size: 42px;
        }
      }
    }

    &-heading {
      line-height: 20px;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 16px;
      }
    }

    &-description {
      line-height: 14.06px;
      margin-top: 6px;
      color: #5B6770;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 12px;
      }

      @media screen and (min-width: $desktop-min-width) {
        font-size: 0.75rem;
      }
    }

    &-link-desktop {
      position: absolute;
      top: 5px;
      right: 1px;
    }

    &-link-mobile {
      margin-top: 20px;
      font-size: 0.85714rem;

      @media screen and (min-width: $tablet-min-width) {
        display: none;
      }
    }

    &-link-icon {
      margin-left: 7.5px;

      svg {
        font-size: 16px;
      }

      &.nav-next-icon {
        margin-left: 0;

        svg {
          font-size: 29.6px;
        }
      }

      &.open-in-new-icon {
        margin-right: 5px;
      }
    }
  }

  &--disabled {
    color: #E1E3E3;

    .tile {
      &-image {
        .logo-wrapper {
          svg {
            color: #E1E3E3;
          }
        }
      }

      &-description {
        color: #E1E3E3;
      }
    }
  }
}