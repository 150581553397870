@import "~styles/variables.scss";

.button {
  font-size: 1rem;
  padding: 5px 16px;
  height: 42px;
  font-weight: bold;
  box-shadow: none;

  &.button-outlined-primary {
    border-color: #007681;
  }

  @media screen and (max-width: $tablet-max-width) {
    font-size: 1rem;
  }

  @media screen and (max-width: $mobile-max-width) {
    font-size: 0.857rem;
    height: 42px;
  }

  &_size {
    &--fullWidth {
      width: 100%;
    }
    &--licenseAgreementWidth {
      width: 285px;
    }

    &--large {
      padding: 4px 39px;
    }

    &--onboard {
      padding: 12px 47px;
    }

    &--modal-btn {
      height: 56px;
      // width: 200px;
    }

    &--exitsimulate-btn {
      height: 40px;
      width: 210px;
      margin-right: 10px;
    }

    &--cancelsimulate-btn {
      height: 56px;
      // width: 200px;
      margin-left: 10px;
    }

    &--admin-landing-btn {
      height: 88px;
      width: 400px;

      @media screen and (min-width: $tablet-min-width) and (max-width: $desktop-middle-width - 1) {
        height: 64px;
        width: 250px;
      }

      @media screen and (max-width: $tablet-min-width - 1) {
        height: 42px;
        width: 190px;
      }
    }
  }
}
