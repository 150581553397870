.modal-root {
  padding: 40px 44px;
  margin: 0.5rem;
}

.modal-form {
  .row {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .customer-name,
  .customer-number {
    width: 80%;
  }
}
input {
  height: 50px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #e3e3e3;
  /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
  border-radius: 3px;
  outline: none;
  /* font-family: "Noto Sans", sans-serif; */
}
.modal-label:after {
  content: " *";
  color: red;
}
.no-asterisk .modal-label:after {
  content: none; /* or you can use display: none; */
  color: transparent; /* or you can use color: transparent; */
}
.col-group {
  margin-bottom: 20px;
  font-size: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.save-button {
  height: 50px;
  outline: none;
  border: none;
  transform: none;
  font-size: 1rem;
  width: 150px;
  background-color: #007681;
  color: white;
}
.search-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  top: 65%;
  display: inline-block;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 12px !important;
}
.Mui-focused {
  border-color: black;
}
@media only screen and (max-device-width: 850px) and (max-device-height: 850px) {
  .col-group {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  .save-button {
    font-size: 1.5rem;
  }
}

@media (min-width: 576px) {
  .modal-root {
    max-width: 500px;
  }
}

@media (min-width: 992px) {
  .modal-root {
    max-width: 900px;
  }
}

@media (min-width: 1920px) {
  input {
    font-size: 1rem;
  }
}
