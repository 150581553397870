.shape_flex {
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }

  &--center {
    justify-content: center;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  &--flex-start {
    justify-content: flex-start;
  }

  &--direction-column {
    flex-direction: column;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-center {
    align-items: center;
  }
}
