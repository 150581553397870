@import "~styles/variables.scss";

.address-section {
  &-sate-zip-code-wrapper {
    display: grid;

    @media screen and (max-width: $mobile-max-width) {
      grid-template-columns: 45px auto;
      column-gap: 24px;
    }

    @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
      grid-template-columns: 1.5fr 5.17fr;
      column-gap: 14%;
    }

    @media screen and (min-width: $desktop-min-width) {
      grid-template-columns: 52px auto;
      column-gap: 54px;
    }
  }

  &-input-line {
    margin-top: 8px;

    &-phone {
      @media screen and (max-width: $mobile-max-width) {
        margin-top: 16px;
      }
    }
  }

  &-heading {
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid #BDBDBD;
  }
}
