@import "~styles/variables.scss";

.bordered-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #BDBDBD;
  line-height: 23px;
  padding-bottom: 8px;
  position: relative;

  .filter{
    position: absolute;
    right: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .generic_select__form .MuiSelect-outlined.MuiSelect-outlined {
    height: 24px;
    padding-left: 7px;
  }
  
  .generic_select__label {
    margin: 0 10px 0 0;
    font-style: normal;
    font-size: 0.75rem;
  }

  .generic_select__select {
    background-color: #fff;
  }
  .generic_select__icon {
    right: -3px;
  }

  @media screen and (max-width: $mobile-max-width) {
    font-size: 16px;
  }

  &-bold {
    font-weight: bold;
  }

  .MuiSvgIcon-root {
    font-size: 1.25rem;
    margin-right: 8px;
  }
}
