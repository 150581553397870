@import "~styles/variables.scss";

.generic-info-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4F4F4F;

  &-window {
    border-radius: 4px;
    outline: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    
    @media screen and (max-width: $mobile-max-width) {
      width: 361px;
      padding: 44px;
    }
    
    @media screen and (min-width: $tablet-min-width) {
      width: 595px;
      padding: 64px 64px 32px;
    }
  }

  &-title {
    color: #007681;
    font-size: 24px;
    margin-bottom: 16px;

    @media screen and (max-width: $mobile-max-width) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &-body {
    margin-bottom: 30px;
    font-size: 16px;
  }

  &-footer {
    display: flex;

    @media screen and (max-width: $mobile-max-width) {
      justify-content: center;
    }

    @media screen and (min-width: $tablet-min-width) {
      justify-content: flex-end;
    }
  }

  .account-modal-button {
    margin-left: 0;
    width: 176px;
  }
}
