.account-info-secondary-tile {
  background-color: white;
  padding: 12px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BDBDBD;
    padding-bottom: 8px;
    font-weight: bold;
    color: #4F4F4F;

    &-no-border {
      border: none;
    }
  }

  &--body {
    display: flex;
    padding: 8px 12px;
  }

  &--edit-btn {
    cursor: pointer;
    font-size: 1rem;
    margin-top: -12px;

    .account-info-edit-icon-root.MuiSvgIcon-root {
      font-size: 1.3333rem;
    }
  }
}
