@media (min-width: 992px) {
  .roles-select-wrapper {
    width: 80%;
  }
}

.roles-select-wrapper-simulation
{
  width:100%
}

.roles-select-outlined-input-root {
  width: 100%;
}

.roles-select-label-root {
  color: #212529;
  line-height: 22px;
}

.roles-select-menu-item-root {
  height: 40px;
}
