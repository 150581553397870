// PastOrdersDetails.scss

.past-orders-details-container {
  @media (max-width: 600px) {
    .mobile-orders-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .mobile-order-details {
      width: 100%;
      margin-bottom: 16px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 8px;

      div {
        margin-bottom: 8px;
      }

      button {
        margin-top: 8px;
      }
    }
  }

  @media (min-width: 601px) {
    .past-orders-details {
      &-paper {
        padding: 0 12px 12px;
        margin-top: 19px;
      }

      .generic-table-root-cell {
        font-size: 1rem;
      }

      button {
        margin-left: 8px;
      }
    }
  }
}
