@import "~styles/variables.scss";

.generic-input {
  &-root {
    .MuiInput-input,
    .MuiOutlinedInput-input {
      padding-top: 12px;
      padding-bottom: 12px;
      height: auto;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #888B8D;
    }

    .MuiOutlinedInput-inputMultiline {
      padding: 0;
    }

    .MuiOutlinedInput-multiline {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    textarea.MuiOutlinedInput-input {
      height: 56px;
    }
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid #E7E8E8
  }
}

.input-label-root {
  color: #4F4F4F;
}

.input-label-asterisk {
  color: #EB5757;
}

.input-label-disabled.MuiFormLabel-root {
  color: #E7E8E8;
  
  .input-label-asterisk {
    color: #E7E8E8;
  }
}

.input-base-root:not(.MuiInputBase-multiline) {
  color: #4F4F4F;

  @media screen and (max-width: $mobile-max-width) {
    font-size: 12px;
    height: 32px;
  }
}

