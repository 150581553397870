@import "~styles/variables.scss";

.landing-page-wrapper {
  color: #4F4F4F;

  .landing-page {
    &--user-info-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #53A4B0;
      mix-blend-mode: multiply;
      opacity: 0.7;
    }

    &--user-info-details {
      position: relative;
      width: 100%;
      height: 80px;
      min-height: 100%;
      // background: url('../../assets/landing_page_background_picture.png');
      background-size: cover;

      @media screen and (max-width: $mobile-max-width) {
        height: 64px;
      }
    }

    &--tiles-container {
      margin-top: 7px;

      @media screen and (max-width: $mobile-max-width) {
        padding: 12px;
      }

      @media screen and (min-width: $tablet-min-width) {
        padding: 16px;
      }

      &-heading {
        &-text {
          margin-left: 13px;

          @media screen and (max-width: $mobile-max-width) {
            font-size: 18px;
            line-height: 20px;
          }

          @media screen and (min-width: $tablet-min-width) {
            font-size: 1.5rem;
          }
        }
      }
    }

    &--tiles {
      display: grid;
      gap: 16px;

      @media screen and (max-width: $mobile-max-width) {
        grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
        margin-top: 12px;
      }

      @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
        grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
        margin-top: 18px;
      }

      @media screen and (min-width: $desktop-min-width) {
        grid-template-columns: repeat(auto-fill, 176px);
        margin-top: 18px;
      }
    }
  }

  .welcome-label,
  .welcome-user-name,
  .user-id-and-role {
    @media screen and (max-width: $mobile-max-width) {
      font-size: 0.85714rem;
    }
  }

  .welcome-user-name {
    margin-right: 4px;
  }

  .user-role {
    margin-left: 4px;
  }

  .welcome-user-text {
    position: absolute;
    padding: 0 32px;
    font-size: 0.875rem;
    display: flex;
    width: 100%;
    color: #F2F2F2;
    flex-direction: row;

    @media screen and (max-width: $mobile-max-width) {
      font-size: 0.85714rem;
      padding: 0 15px;
      top: 10px;
      flex-direction: column;
    }

    @media screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
      align-items: baseline;
      top: 18px
    }

    @media screen and (min-width: $desktop-min-width) {
      align-items: baseline;
      top: 14px
    }
  }

  .welcome-customer-name {
    @media screen and (min-width: $tablet-min-width) {
      font-size: 2rem;
      margin-right: 25px;
    }

    @media screen and (max-width: $mobile-max-width) {
      font-size: 1.42857rem;
      line-height: 23px;
    }
  }

  .user-address {
    @media screen and (min-width: $tablet-min-width) {
      display: flex;
      align-self: flex-end;
    }

    @media screen and (max-width: $mobile-max-width) {
      margin-top: 24px;
    }
  }
}