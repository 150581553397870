.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6d6d6d;
  background-color: transparent;
  z-index: 2000;
  cursor: none;
  pointer-events: none;
}
.overlay-none {
  display: none;
}

.spinner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  font-size: 50px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
