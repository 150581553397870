@import "~styles/variables.scss";

.generic-modal-button {
  height: 42px;
  font-weight: bold;

  @media screen and (max-width: $mobile-max-width) {
    font-size: 1.14285rem;
    padding-left: 0;
    padding-right: 0;
  }

  &:last-child {
    margin-left: 16px;
  }
} 
