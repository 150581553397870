@import "~styles/variables.scss";

.billing-modal {
  .generic-modal-window {
    @media screen and (max-width: $mobile-max-width) {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: $tablet-min-width) {
      width: 941px;
    }
  }

  .generic-modal {
    &-footer {
      @media screen and (min-width: $tablet-min-width) {
        justify-content: flex-end;
      }
    }
  }

  .address-section-wrapper {
    flex: 1;

    @media screen and (min-width: $tablet-min-width) {
      margin-right: 64px;
    }
  }

  &-form {
    display: flex;

    @media screen and (max-width: $mobile-max-width) {
      flex-direction: column;
    }

    @media screen and (min-width: $tablet-min-width) {
      justify-content: space-between;
    }
  }

  &-contact-wrapper {
    flex: 1;

    @media screen and (max-width: $mobile-max-width) {
      margin-top: 36px;
    }
  }

  &-heading {
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid #BDBDBD;
  }

  &-input-line {
    margin-top: 8px;
  }
}
