.table-mobile {
  width: 100%;
  height: 100%;
  color: #6d6d6d;
  overflow: auto;
}
.table-mobile-row {
  height: 150px;
  border-top: 0.1rem solid lightgray;
  padding: 10px 20px;
  display: flex;
}
.table-mobile-data {
  width: 96%;
  display: inline-block;
  font-weight: 600;
}
.image-ellipses {
  height: 20px !important;
  width: 20px !important;
  vertical-align: middle;
  color: #6d6d6d;
  margin-top: 24px !important;
}
.table-mobile-customer-number,
.table-mobile-customer-email,
.table-mobile-role .table-mobile-invited {
  font-size: 0.85rem;
  padding-top: 5px;
}
.table-mobile-invited {
  font-style: italic;
}
.table-img-status {
  width: 16px;
}
.table-mobile-popup-row {
  border-bottom: 1px solid #6d6d6d;
  height: 30px;
  margin: 10px;
}
.table-mobile-popup-text {
  font-size: 0.85rem;
  padding: 12px 15px 12px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E7E8E8;
  cursor: pointer;
}
.edit-customer-popover {
  box-shadow: 0px 4px 8px #A0A0A021;
}
.edit-customer-popover-inner-wrapper {
  width: 181px;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}
