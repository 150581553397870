.account-info-primary-tile {
  background-color: #F2F2F2;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  
  &--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BDBDBD;
    font-size: 1.125rem;
    margin: 12px;
    position: relative;
    line-height: 23.5px;
    padding-bottom: 12px;
  }

  &--body {
    display: flex;
    padding: 0 7px 12px 12px;
    margin-right: 5px;
  }

  &--edit-btn {
    cursor: pointer;
    font-size: 1rem;
    margin-top: -12px;
    position: absolute;
    top: 0;
    right: 0;

    .account-info-edit-icon-root.MuiSvgIcon-root {
      font-size: 1.3333rem;
    }
  }
}
