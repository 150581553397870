@import '~styles/mixins.scss';

.book-service-modal {
  @include modal;

  &-date-time {
    &-picker {
      width: 100%;

      .MuiOutlinedInput-adornedEnd {
        height: 40px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #888B8D;
      }
    }

    &-icon {
      font-size: 20px;
    }
  }

  &-phone-icon {
    font-size: 20px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
  }

  .pump-hours-file-upload-text {
    margin-bottom: 4px;
    line-height: 20px;
    font-size: 1rem;
  }

  .pump-hours-file-restrictions-text {
    font-size: 0.75rem;
    line-height: 12px;
    margin-top: 4px;
  }
  .add-file-button-label {
    margin: 0;

    .button {
      height: 40px;
    }
  }
  .pump-hours-chosen-images {
    margin-top: 24px;
  }

}
