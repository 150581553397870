@import "~styles/variables.scss";

.installation-wrapper {
  @media screen and (max-width: $mobile-max-width) {
    margin-bottom: 12px;
  }

  @media screen and (min-width: $tablet-min-width) {
    flex: 0 0 31.7%;
    margin: 0.6%;
  }

  .account-info-primary-tile--header {
    font-size: 1rem;
    padding-bottom: 8px;
  }
  
  .account-info-secondary-tile {
    margin-bottom: 8px;
    padding-top: 6px;
    padding-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &--body {
      padding: 0;
    }

    &--header {
      padding: 0;
      align-items: center;

      &-text {
        font-size: 1.14285rem;
        font-weight: bold;
      }
    }

    &--edit-btn {
      padding: 8px;
      margin-top: 0;
    }
  }
  
  .account-info-primary-tile {
    &--header {
      margin-bottom: 8px;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 1.14285rem;
      }
    }

    &--body {
      flex-direction: column;
      max-height: 178px;
      overflow-y: auto;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 0.85714rem;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: #BABCBE;
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-track {
        box-shadow: none;
      }
    }
  }
}
