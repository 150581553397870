@import "~styles/variables.scss";

.edit-alias-success-modal {
  .generic-info-modal {
    &-window {
      @media screen and (min-width: $tablet-min-width) {
        width: 386px;
      }
    }
  }
}
