@import "~styles/mixins.scss";

.order-history {
  &-wrapper {
    color: #4F4F4F;
    padding: 36px 16px;
  }

  &-header {
    @include flow-page-main-header;
  }

  &-header-icon {
    font-size: 3.07375rem;
    margin-right: 22px;
  }

  &-header-title {
    margin-top: 0;
  }
}

iframe {
  border: none;
}
.report-style-class {
  height: 95vh;
  margin: 0;
  width: 100%;
}
.not-authorized {
  font-size: 3rem;
  height: 40vh;
  display: flex;
  justify-content: center;
  margin-top: 30vh;
}

.select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  margin-left: 25px;
}

.select-wrapper select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
  width: 165px;
}

.select-wrapper select:focus {
  border-color: #007bff;
  outline: none;
}