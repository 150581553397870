@import "~styles/mixins.scss";

.contact-us {
  padding: 15px 30px;
  color: #4f4f4f;

  &-header {
    @include flow-page-main-header;
  }

  &-body {
    margin-top: 30px;

    &-icon {
      font-size: 3.6875rem;

      &-text {
        font-size: 1.5rem;
        color: #007681;
        font-weight: bold;
        margin-left: 6px;
      }
    }
  }

  &-phone {
    color: #007681;
    font-size: 1.5rem;
    margin: 16px 0 15px;
  }

  &-mail {
    display: block;
    color: #007681;
    font-size: 1.125rem;
    margin: 18px 0 56px;
    text-decoration: underline;
  }

  &-hours {
    &-label {
      font-weight: bold;
    }
    &-label,
    &-text {
      text-align: left;
      line-height: 16px;
      font-size: 1rem;
    }
  }

  &-write-us-wrapper,
  &-call-us-wrapper {
    width: 369px;
    text-align: center;
    padding: 8px;
    border: none;
    
    .generic-paper {
      height: 100%;
      padding: 37px 18px 12px;
    }
  }

  &-write-us-wrapper {
    .generic-paper {
      padding: 38px 18px 12px;
    }
  }

  &-working-hours-divider {
    margin: 0 10px;
  }

  &-call-us-wrapper {
    margin-right: 16px;
  }

  // Media query for responsiveness
  @media only screen and (max-width: 767px) {
    .contact-us-body {
      display: flex;
      flex-direction: column;
    }

    .contact-us-write-us-wrapper,
    .contact-us-call-us-wrapper {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }

    .contact-us-call-us-wrapper {
      margin-top: 16px; // Add top margin for spacing
    }
  }
}
