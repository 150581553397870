@import "~styles/mixins.scss";

.service-history {
  padding: 30px 10px 30px 10px;
  color: #4F4F4F;

  &-header {
    @include flow-page-main-header;
  }
}
