.manage-customer {
  height: 100vh;
  padding: 25px 50px;
}
.manage-user-mobile {
  height: 100vh;
  padding: 10px 10px;
  margin-top: 16px;
  padding-top: 0;
}
.manage-table {
  margin-top: 20px;
  height: calc(100% - 70px);
}
.manage-buttons {
  height: 50px;
  outline: none;
  border: none;
  transform: none;
  font-size: 1rem;
  width: 300px;
  background-color: #007681;
  color: white;
}

@media only screen and (max-device-width: 1192px) and (max-device-height: 1366px) {
  .manage-buttons {
    font-size: 2rem;
  }
}
